import React from "react";
import { Paper, Typography, Grid } from "@mui/material";
import { Language, Instagram } from "@mui/icons-material";

import Profile from "../Profile/Profile";
import JobCard from "../JobCard/JobCard";
import profile from "../../../assets/profile_pic.jpg";
import hickoryLogo from "../../../assets/hickory_chair_logo.jpeg";
import hudsonLogo from "../../../assets/hudson_valley_logo.jpg";
import bustraanLogo from "../../../assets/bustraan_logo.jpeg";
import { SALES_EMAIL } from "../../../constants";

export default function BioPage() {
  return (
    <Paper sx={styles.root}>
      <Grid container spacing={0} justifyContent="center">
        <Grid item xs={12} sm={6}>
          <img src={profile} alt="Harris Thomas" style={styles.img} />
        </Grid>

        <Grid item sm={6}>
          <Profile />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4" sx={styles.subtitle}>
            Luxury Home Furnishings & Lighting Sales
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} sx={styles.cards}>
          <JobCard
            title="Hickory Chair"
            city="New York, New Jersey, Philadelphia Metro"
            email={SALES_EMAIL}
            logo={hickoryLogo}
            links={[
              {
                icon: Language,
                title: "Hickory Chair Website",
                url: "http://www.hickorychair.com",
              },
              {
                icon: Instagram,
                title: "Hickory Chair Instagram",
                url: "https://www.instagram.com/hickorychair/",
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={styles.cards}>
          <JobCard
            title="Hudson Valley Lighting Group"
            city="New York City, Eastern PA, South NJ, Delaware"
            email={SALES_EMAIL}
            logo={hudsonLogo}
            links={[
              {
                icon: Language,
                title: "Hudson Valley Lighting Website",
                url: "https://www.hvlgroup.com",
              },
              {
                icon: Instagram,
                title: "Hudson Valley Lighting Instagram",
                url: "https://www.instagram.com/hudsonvalleylighting/",
              },
              {
                icon: Instagram,
                title: "Mitzi Lighting Instagram",
                url: "https://www.instagram.com/mymitzi/",
              },
              {
                icon: Instagram,
                title: "Troy Lighting Instagram",
                url: "https://www.instagram.com/troylighting/",
              },
              {
                icon: Instagram,
                title: "Corbett Lighting Instagram",
                url: "https://www.instagram.com/corbettlighting/",
              },
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} sx={styles.cards}>
          <JobCard
            title="Bustraan Fine Art"
            city="New York, New Jersey, Pennsylvania"
            email={SALES_EMAIL}
            logo={bustraanLogo}
            links={[
              {
                icon: Language,
                title: "Bustraan Fine Art Website",
                url: "https://www.donaldbustraan.art",
              },
            ]}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

const styles = {
  root: {
    width: "100%",
    maxWidth: 1000,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    p: 2,
    alignItems: "center",
  },
  subtitle: {
    textAlign: "center",
    textTransform: "uppercase",
    my: 1,
  },
  cards: {
    p: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    width: "100%",
    borderRadius: "10px",
  },
};
